import React from 'react';

// data
import content from '../../data/content-fundamentals'

function Content() {

	return (
		<div>
			<ul className="list-group list-group-flush">
				{
					// looping over the main items in content-fundamentals.js
					content.map((item, i) =>
						<li className="list-group-item"
							key={i}>
							<h3>Module {i + 1}: {item.header}</h3>
							{
								// looping over the nested (text) items
								item.text.map((text, j) =>
									<p key={j}
									   style={{paddingLeft: '20px'}}>
										{text}
									</p>
								)
							}
						</li>
					)
				}
			</ul>
		</div>
	);
}

export default Content;
