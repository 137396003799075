import React from 'react';

// Router Stuff
import {BrowserRouter, Route, Switch} from "react-router-dom";

// Navigation
import FooterNavigation from "./navigation/FooterNavigation";
import MainNavigation from "./navigation/MainNavigation";

// Pages
import Home from "./home";
import Training from "./training";
import Dates from "./dates";
import Information from "./information";
import Contact from "./contact";

function App() {
    return (
        <BrowserRouter>
            <MainNavigation/>
            <Switch>
                <Route path="/" exact>
                    <Home/>
                </Route>
                <Route path="/training">
                    <Training/>
                </Route>
                <Route path="/dates">
                    <Dates/>
                </Route>
                <Route path="/information">
                    <Information/>
                </Route>
                <Route path="/contact">
                    <Contact/>
                </Route>
            </Switch>
            {/*<HomePageBell/>*/}
            {/**************** Footer *****************/}
            <FooterNavigation/>

        </BrowserRouter>
    )
}

export default App;
