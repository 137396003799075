const prerequisites = [
    {
        header: 'HTML',
        text: 'Some knowledge of HTML and CSS.'
    },
    {
        header: 'JavaScript',
        text: 'Thorough knowledge of JavaScript (functions, arrays, objects, functional programming).'
    },
    {
        header: 'Debugging',
        text: 'Experience with debugging web applications in Google Chrome.'
    },
    {
        header: 'MVC',
        text: 'Experience with an MVC-framework like .NET MVC, Java Spring Boot or PHP Laravel, or a frontend framework like Angular of React may come in handy, but is not absolutely necessary.'
    },
    {
        header: 'NodeJS',
        text: 'NodeJS 10.x or higher installed on your laptop. Some knowledge of  NodeJS, working in a command line environment (DOS-prompt) and installing NPM-packages.'
    },
    {
        header: 'English',
        text: 'Some knowledge of the English language. Many websites, documentation and background information are only available in English.'
    }
];
export default prerequisites;
