import React from 'react';
import {Parallax} from "react-parallax";
import {Link} from "react-router-dom";

function HomeParallax() {

    return (
        <section className='parallax'>
            <Parallax
                bgImage={require('../../img/reacttraining-code-bg2.nl.png')}
                bgImageAlt="Parallax background image"
                strength={500}
                blur={2}
            >
                <div className="container text-white text-center">
                    <div className="row" style={{padding: '40px 0'}}>
                        <div className="col">
                            <h2>Learn React with us!</h2>
                            <p className="tagline">Kickstart your React knowledge today.</p>
                            <Link to="/training" className="btn">
                                Learn More
                            </Link>
                        </div>
                    </div>
                </div>
            </Parallax>
        </section>
    );
}

export default HomeParallax;
