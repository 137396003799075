import React, {useEffect} from 'react';
import flagNl from '../../img/nl.png';
import flagUs from '../../img/us.png';

import courseSummaryUS from '../../assets/Course summary React Fundamentals.pdf';
import courseSummaryNL from '../../assets/Course summary React Fundamentals-NL.pdf';

// Routing stuff
import {Link, NavLink, Redirect, Route, Switch, useRouteMatch} from "react-router-dom";

// Components
import Content from "./Content";
import Prereq from "./Prereq";
import More from "./More";

function TrainingPage() {
    // destructure the routes to be used in nested navigation
    let {path, url} = useRouteMatch();

    // Scroll to top when the user enters the page, but only when the
    // path has changed (so not on internal, nested routing)
    useEffect(()=>{
        window.scrollTo(0,0);
    }, [path]);

    return (
        <div className="container">
            <div className="row">
                <div className="col">
                    {/***************************************************/}
                    {/* Jumbotron */}
                    {/***************************************************/}
                    <div className="jumbotron">
                        <h1>What's in the training?</h1>
                       <p>
                            <a href={courseSummaryUS}>Extensive course summary (English, PDF, 121 KB)</a>
                            <img src={flagUs} alt="Flag USA"/>
                        </p>
                        <p>
                            <a href={courseSummaryNL}>Uitgebreide samenvating (Nederlands, PDF, 124 KB)</a>
                            <img src={flagNl} alt="Flag Netherlands"/>
                        </p>
                        <p className="text-right">
                            <Link className="btn" to="/contact">Sign up!</Link>
                        </p>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    {/***************************************************/}
                    {/*Navigation links for training hardcoded...*/}
                    {/***************************************************/}
                    <ul className="nav nav-pills">
                        <li className="nav-item">
                            <NavLink to={`${url}/content`}
                                     activeClassName="router-link-active"
                                     className="nav-link">
                                Content
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to={`${url}/prereq`}
                                     activeClassName="router-link-active"
                                     className="nav-link">
                                Prerequisites
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to={`${url}/more`}
                                     activeClassName="router-link-active"
                                     className="nav-link">
                                More...
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    {/***************************************************/}
                    {/* Content of the tabs */}
                    {/***************************************************/}
                    <Switch>
                        <Route path={`${path}/content`}>
                            <Content/>
                        </Route>
                        <Route path={`${path}/prereq`}>
                            <Prereq/>
                        </Route>
                        <Route path={`${path}/more`}>
                            <More/>
                        </Route>
                        <Route>
                            {/*Default route*/}
                            <Redirect to={`${path}/content`} />
                        </Route>
                    </Switch>
                </div>
            </div>
        </div>
    );
}

export default TrainingPage;
