import React, {useEffect, useRef} from 'react';

// Creating a Google Map from scratch in React.
// Credits: https://engineering.universe.com/building-a-google-map-in-react-b103b4ee97f1
// Other Article, using the google-maps-react library from fullstack.io ( but decided not
// to use in this case): https://dev.to/jessicabetts/how-to-use-google-maps-api-and-react-js-26c2

// My variables
const GOOGLE_MAP_API_KEY = 'AIzaSyAWh-P7BQdfppx4zmtS8Mjd8UP0ZyqQwUY';
const myLocation = { // Laarstraat 47, Zutphen, NL
    lat: 52.141328,
    lng: 6.201374
};
// styles
const mapStyles = {
    width: '100%',
    height: '400px',
};

function GoogleMaps(props) {
    // refs
    const googleMapRef = React.createRef();
    const googleMap = useRef(null);
    const marker = useRef(null);

    // helper functions
    const createGoogleMap = () =>
        new window.google.maps.Map(googleMapRef.current, {
            zoom: 14,
            center: {
                lat: myLocation.lat,
                lng: myLocation.lng
            }
        });

    const createMarker = () =>
        new window.google.maps.Marker({
            position: {
                lat: myLocation.lat,
                lng: myLocation.lng
            },
            map: googleMap.current
        });

    // useEffect Hook
    useEffect(() => {
        const googleMapScript = document.createElement('script');
        googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAP_API_KEY}&libraries=places`;
        window.document.body.appendChild(googleMapScript);

        googleMapScript.addEventListener('load', () => {
            googleMap.current = createGoogleMap();
            marker.current = createMarker()
        });

        // componentWillUnmount(), reset Google Maps to prevent errors on subsequent load.
        return () => {
           window.google = {}
        }
    });

    // UI
    return (
        <div
            id="google-map"
            ref={googleMapRef}
            style={mapStyles}
        />
    )
}

export default GoogleMaps
