import React from 'react';

// Components on home page
import HomeAbout from "./HomeAbout";
import HomeHero from "./HomeHero";
import ContactPlusAddress from "./ContactPlusAddress";
import HomeParallax from "./HomeParallax";
import HomeFeatures from "./HomeFeatures";

function HomePage() {
    return (
        <>
            {/************ Hero *****************/}
            <HomeHero/>

            {/**************** About *****************/}
            <HomeAbout/>

            {/**************** Parallax *****************/}
            <HomeParallax />

            {/**************** Features *****************/}
            <HomeFeatures/>

            {/**************** Map/Contact *****************/}
            <ContactPlusAddress/>
        </>
    )
}

export default HomePage
