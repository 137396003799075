import React from 'react';

// data
import more from '../../data/content-abstract'

// some local styles
const pStyles = {
	paddingLeft: '20px',
	marginBottom: '12px',
	lineHeight: '24px'
};

function More() {

	return (
		<div>
			<ul className="list-group list-group-flush">
				{
					// looping over the main items in content-fundamentals.js
					more.map((item, i) =>
						<li className="list-group-item"
							key={i}>
							<h3>{item.header}</h3>
							{
								// looping over the nested (text) items
								item.text.map((text, j) =>
									<p key={j}
									   style={pStyles}>
										{text}
									</p>
								)
							}
						</li>
					)
				}
			</ul>
		</div>
	);
}

export default More;
