import React from 'react';

// data
import prerequisites from '../../data/prerequisites'

function Prereq() {

	return (
		<div>
			<ul className="list-group list-group-flush">
				{
					// looping over the main prerequisites
					prerequisites.map((item, i) =>
						<li className="list-group-item"
							key={i}>
							<h3>{item.header}</h3>
							<p
								style={{paddingLeft: '20px'}}>
								{item.text}
							</p>
						</li>
					)
				}
			</ul>
		</div>
	);
}

export default Prereq;
