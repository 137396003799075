import React, {Component} from 'react';
import {Link, NavLink} from "react-router-dom";
import navLinks from "../../data/NavigationLinks";

class MainNavigation extends Component {
    render() {
        return (
            <nav className="navbar navbar-expand-lg sticky-top navbar-dark bg-primary">
                <Link to="/" className="navbar-brand">ReactTraining.nl</Link>
                <button className="navbar-toggler" type="button" data-toggle="collapse"
                        data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                        aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"/>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav mr-auto">
                        {
                            navLinks.map((link, index) => {
                                    // Home page should not be styled differently if not an exact match
                                    if (link.path === '/') {
                                        return <li className="nav-item" key={index}>
                                            <NavLink to={link.path} exact={true} activeClassName="router-link-active"
                                                     className="nav-link">
                                                {link.text}
                                            </NavLink>
                                        </li>
                                    } else {
                                        // all other routes
                                        return <li className="nav-item" key={index}>
                                            <NavLink to={link.path}
                                                     activeClassName="router-link-active"
                                                     className="nav-link">
                                                {link.text}
                                            </NavLink>
                                        </li>
                                    }
                                }
                            )
                        }
                    </ul>
                </div>
            </nav>
        );
    }
}

export default MainNavigation;
