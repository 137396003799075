const contentFundamentals = [
    {
        header: 'What is React, overview of the React landscape',
        text: [
            'What a React application looks like',
            'Tooling - working with Create-React-App, React Devtools, Node.js and browser',
            'Your first React Project'
        ]
    },
    {
        header: 'Setting up the environment',
        text: [
            'Creating a project with Create React App',
            'Starting the project',
            'Customizing the home page'
        ]
    },
    {
        header: 'React Components and composition',
        text: [
            'Class based components vs Function based Components',
            'Data and state in components',
            'One-way dataflow principle',
            'Nesting components',
            'Passing props to components',
            '"Lifting state up"',
            'Short introduction in React Hooks'
        ]
    },
    {
        header: 'Working with data',
        text: [
            'Loading and showing external data',
            'Showing details',
            'Reacting to events'
        ]
    },
    {
        header: 'Class based lifecycle hooks',
        text: [
            'What are lifecycle hooks?',
            'ComponentDidMount()',
            'ComponentDidUpdate()',
            'ComponentWillUnmount()',
            'Deprecated lifecycle hooks'
        ]
    },
    {
        header: 'Styling React Components',
        text: [
            'Global and local CSS',
            'Working with CSS modules',
            'Working with CSS preprocessors like SASS',
            'Definining inline styles as constants'
        ]
    },
    {
        header: 'Handling User Input',
        text: [
            'Working with form input textfields',
            'More fields: radio buttons, checkboxes, textareas',
            'Selecting user input from dropdown lists'
        ]
    },
    {
        header: 'Communicating with external API\'s',
        text: [
            'How does React handle external data?',
            'Working with axios',
            'Creating and firing AJAX-requests',
            'Processing AJAX-responses',
            'Creating loading indicator (aka Spinner) components'
        ]
    },
    {
        header: 'Working with React Router',
        text: [
            'What is routing in webapplications?',
            'An alternative: Reach Router',
            'Routing Basics - installation, linking to routes',
            'Styling the active link',
            'Routing parameters',
            'Navigating via code'
        ]
    },
    {
        header: 'React Hooks in depth',
        text: [
            'Functional components revisited',
            'The useState() Hook',
            'The useEffect() Hook',
            'ES6 destructuring syntax',
            'Creating your own Hooks',
        ]
    },
    {
        header: 'A short introduction to Redux',
        text: [
            'What is state management?',
            'Why Redux',
            'The basics of Store, Actions, Reducers and Dispatchers',
            'Next steps'
        ]
    },
    {
        header: 'Deployment',
        text: [
            'Deploying your React app to a production server',
            'Deploying to a local webserver',
            'Deploying to a free online hosting provider',
        ]
    }
];
export default contentFundamentals
