import React from 'react';
import {Link} from "react-router-dom";
import navLinks from '../../data/NavigationLinks'

function FooterNavigation() {

    return (
        <div>
            <footer className="site-footer">
                <div className="bottom">
                    <div className="container">
                        <div className="row">

                            <div className="col-lg-6 col-xs-12 text-lg-left text-center">
                                <p className="copyright-text">
                                    &copy; BELL Theme
                                </p>
                                <div className="credits">

                                    {/*<!--*/}
                                    {/*  All the links in the footer should remain intact.*/}
                                    {/*  You can delete the links only if you purchased the pro version.*/}
                                    {/*  Licensing information: https://bootstrapmade.com/license/*/}
                                    {/*  Purchase the pro version with working PHP/AJAX contact form: https://bootstrapmade.com/buy/?theme=Bell*/}
                                    {/*-->*/}

                                    Designed by <a href="https://bootstrapmade.com/" target="_blank" rel="noopener noreferrer">
                                        BootstrapMade
                                    </a>
                                </div>
                            </div>

                            <div className="col-lg-6 col-xs-12 text-lg-right text-center">
                                <ul className="list-inline">
                                    {navLinks.map((link, index) => {
                                        return (
                                            <li className="list-inline-item" key={index}>
                                                <Link to={link.path}>
                                                    {link.text}
                                                </Link>
                                            </li>
                                        )
                                    })}
                                </ul>
                                <hr className="bg-white"/>
                                <p className="credits">
                                    Reacttraining.nl is part of <a href="https://kassenaar.com/" target="_blank" rel="noopener noreferrer">
                                        Kassenaar IT Services
                                    </a> - &copy; {new Date().getFullYear()}
                                </p>
                            </div>

                        </div>
                    </div>
                </div>
            </footer>
            <a className="scrolltop" href="/#">
                <span className="fa fa-angle-up" /></a>
        </div>
    );
}

export default FooterNavigation;
