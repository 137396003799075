const contentAbstract = [
    {
        header: 'Abstract',
        text:[
            'React is probably the most popular front-end library for creating web applications. Originally, React was created by Facebook, but in 2013 the library was made available as open source. Anyone can work with the source code. React is used by Instagram, Airbnb, Uber, Netflix, Slack and many other companies and organizations, among others.',
            'The React Fundamentals course teaches you the basics of React applications and explains the structure and architecture of React projects. The underlying ideas and concepts of React are discussed in detail. Of course, you will then start working with your own code.',
            'You will learn all about components, the JSX syntax and working with props, state and events. More advanced topics such as communicating with external APIs, working with React Router and the new addition React Hooks will also be discussed. At the end of the training, you can independently create and maintain React applications, or get started with applications developed by others.',
        ]
    },
    {
        header: 'Target audience',
        text: [
            'The React Fundamentals training course is intended for web developers who want to work with this library and have a thorough basic knowledge of JavaScript. In addition, knowledge of HTML and CSS is indispensable',
            'Developers who have a background in Java or C# can usually also quickly get on with React. The most important thing, however, is that your JavaScript knowledge is up to date, including the new ES6 expansions, because this is the language in which programming will eventually take place.'
        ]
    },
    {
        header: 'Training goal',
        text:[
            'After this training, you are familiar with the React ecosystem and you will be able to set up React applications yourself, maintain, expand and roll out other people\'s applications to production.'
        ]
    }
];

export default contentAbstract
