import React from 'react';

function HomeFeatures() {

    return (
        <section className="features" id="features">
            <div className="container">

                <h2 className="text-center">What others say...</h2>
                <div className="row">
                    {/*Testimonials #1 */}
                    <div className="feature-col col-lg-4 col-xs-12">
                        <div className="card card-block text-center">
                            <div>
                                <div className="feature-icon">
                                    <span className="fa fa-rocket"/>
                                </div>
                            </div>

                            <div>
                                <h3>
                                    "Excellent teacher"
                                </h3>

                                <p>
                                    "Teacher with excellent knowledge. There was enough time for questions, but also the
                                    opportunity to anticipate to excersises in advance."
                                </p>
                                <p className="text-muted">
                                    - Johan Helmus, ATOS
                                </p>
                            </div>
                        </div>
                    </div>

                    {/*Testimonials #2 */}
                    <div className="feature-col col-lg-4 col-xs-12">
                        <div className="card card-block text-center">
                            <div>
                                <div className="feature-icon">
                                    <span className="fa fa-exclamation"/>
                                </div>
                            </div>

                            <div>
                                <h3>
                                    "Great course"
                                </h3>

                                <p>
                                    "Good course, good explanation. You could do the exercises from scratch, or based on
                                    available front-end code. Good examples."
                                </p>
                                <p className="text-muted">
                                    - Mona-Marie Kolff, SVB
                                </p>
                            </div>
                        </div>
                    </div>

                    {/*Testimonials #3 */}
                    <div className="feature-col col-lg-4 col-xs-12">
                        <div className="card card-block text-center">
                            <div>
                                <div className="feature-icon">
                                    <span className="fa fa-bolt"/>
                                </div>
                            </div>

                            <div>
                                <h3>
                                    "Learned so much"
                                </h3>

                                <p>
                                    "I really learned a lot, and the explanations during the course were very clear."
                                </p>
                                <p className="text-muted">
                                    - Johan Smits, Saxion
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </section>
    );
}

export default HomeFeatures;
