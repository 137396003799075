import React from 'react';
import ReactLogo from "../../img/1280px-React-icon.svg.png";
import {Link} from "react-router-dom";
import {Parallax} from "react-parallax";

// hack, to override default section style in Bell style.css
const sectionStyle = {
    margin: 0,
    padding: 0
};

function HomeHero() {

    return (
        <section style={sectionStyle}>
            <Parallax
                bgImage={require('../../img/reacttraining-code-bg1.nl.png')}
                bgImageAlt="Parallax background image"
                strength={500}
                blur={2}
            >
                <div className="container text-center my-5">
                    <div className="row">
                        <div className="col-md-12">
                            <img alt="React Logo"
                                 className="reactLogo"
                                 src={ReactLogo}/>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <h1 className="text-white">
                            We have switched gears
                        </h1>

                        <p className="tagline">
                            Currently no trainings are provided.
                            Read the <a href="https://www.kassenaar.com/blog/post/2023/03/06/Veranderingen.aspx"
                                        target="_blank">full statement</a> here.
                        </p>
                        <a href="https://www.kassenaar.com/blog/post/2023/03/06/Veranderingen.aspx" target="_blank"
                           className="btn">Full statement</a>
                    </div>
                </div>
            </Parallax>
        </section>
    );
}

export default HomeHero;
