import React from 'react';
import GoogleMaps from "../GoogleMaps/GoogleMaps";

function ContactPlusAddress () {

  return (
      <section className="contact">
          <div className="container text-center">
              <div className="row">
                  <div className="col-md-8">
                      <GoogleMaps/>
                  </div>
                  <div className="col-md-4">
                      <div className="card">
                          <div className="card-body">
                              <h2 className="card-title">Contact us</h2>
                              <address>
                                  <p>
                                      <i className="fa fa-3x fa-map-marker"/><br/>
                                      Laarstraat 47a <br/>
                                      7201 CB Zutphen <br/>
                                      Netherlands
                                  </p>
                              </address>
                              <p>
                                  <i className="fa fa-3x fa-phone"/><br/>
                                  +31-(0)6 233 371 65
                              </p>
                              <p>
                                  <i className="fa fa-3x fa-envelope"/><br/>
                                  <a href="mailto:info@reacttraining.nl">info@reacttraining.nl</a>
                              </p>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </section>
  );
}

export default ContactPlusAddress;
