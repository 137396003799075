import React, {useEffect} from 'react';
import {Link} from "react-router-dom";
import ContactPlusAddress from "../home/ContactPlusAddress";

// Check: https://medium.com/@everdimension/how-to-handle-forms-with-just-react-ac066c48bd4f


function Contact() {

    // scroll to top on enter
	useEffect(() => {
		window.scrollTo(0, 0)
	}, []);

	return (
		<div className="container">
			{/*Row - form + Text*/}
			<div className="row my-3">
				<div className="col-md-8">
					<div className="contact-form">
						<h2 className="text-center my-3">Contact us</h2>
						{/*Start of the form */}
						<form action="https://formspree.io/info@kassenaar.com" method="post">
							<div className="form-group">
								<input type="text"
									   name="name"
									   className="form-control"
									   id="name"
									   required
									   placeholder="Name *"/>
							</div>
							<div className="form-group">
								<input type="email"
									   className="form-control"
									   name="_replyto"
									   id="email"
									   required
									   placeholder="Email * "/>
							</div>
							<div className="form-group">
								<div className="validation"/>
								<input type="text"
									   className="form-control"
									   name="subject"
									   id="subject"
									   required
									   placeholder="Subject *"/>
							</div>
							<div className="form-group">
								<textarea className="form-control"
										  name="message"
										  rows="5"
										  placeholder="Message"/>
							</div>
							<div className="text-center">
								<button type="submit" className="btn">Send Message</button>
							</div>
						</form>
					</div>
					<hr/>
				</div>
				<div className="col-md-4">
					<h3>Booking a class?</h3>
					<p>Make sure to check the <Link to="/dates">dates</Link> page and include
						the name and date of the class of the class you want to attend in your message.
					</p>
					<p>Also make sure we can reach you by including your
						email address and/or phone number.
					</p>
					<p>Prefer to talk to us via mail?
						Send your email to <a href="mailto:info@reacttraining.nl">info@reacttraining.nl</a>.
					</p>
					<p><strong>No need to pay yet.</strong> We will contact you on invoicing options.</p>
					<p>
						Usually we reply within 1 business day.
					</p>
					<hr/>
				</div>
			</div>

			{/*Row - Google maps + address*/}
			<div className="row my-3">
				<div className="col">
					<ContactPlusAddress/>
				</div>
			</div>
		</div>
	);
}

export default Contact;
