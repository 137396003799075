import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';

// styles
// import "bootstrap/dist/css/bootstrap.min.css"
// https://bootswatch.com/flatly/
import 'bootstrap/dist/js/bootstrap.min'
import './css/bootstrap.flatly-theme.min.css'
import "font-awesome/css/font-awesome.min.css"
import "./css/style.css"
import "./css/pk-styles.css"

ReactDOM.render(<App/>,
    document.getElementById('root'));
