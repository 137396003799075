const navLinks=[
    {
        path: '/',
        text: 'Home'
    },
    {
        path: '/training',
        text: 'Training'
    },
    {
        path: '/dates',
        text: 'Dates'
    },
    {
        path: '/information',
        text: 'Information'
    },
    {
        path: '/contact',
        text: 'Contact'
    }
];

export default navLinks
