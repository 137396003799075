import React, {useState, useEffect} from 'react';
import {Link} from "react-router-dom";
import axios from 'axios'

// flags
import flagNl from '../../img/nl.png';
import flagUs from '../../img/us.png';

function Dates() {
	const apiUrl = 'https://www.reacttraining.nl/api/dates.json';
	const [dates, setDates] = useState([]);
	useEffect(() => {
		// componentDidMount()
		axios.get(apiUrl)
			.then(response => {
				// only include course dates that are in the future
				const validDates = response.data.filter(date =>{
					return Date.parse(date.startDate) > Date.parse(new Date().toUTCString())
				});
				setDates(validDates)
			});
		// componentWillUnmount()
		return () => {
		};
	}, /*deps*/ []);


	return (
		<div className="container">
			{/* Row - table */}
			<div className="row my-3">
				<div className="col-md-8">
					<h3>Public class dates</h3>
					<p>In person, live, instructor-led classes.
					</p>
					<table className="table table-striped">
						<tbody>
						<tr>
							<th>Date</th>
							<th>Subject</th>
							<th>Duration</th>
						</tr>
						{
							dates.length ?
							dates.map(date => <tr key={date.id}>
								<td>{date.dateText}</td>
								<td>{date.subject}</td>
								<td>{date.duration}</td>
							</tr>)
								:
								<tr>
									<td colSpan={2}><em>Due to heavy demand for private classes,
										currently no public classes planned.
										See the Calendar below for availability.</em>
									</td>
									<td>&nbsp;</td>
								</tr>
						}

						<tr>
							<td colSpan={2}><strong>As always: contact us for a private class at your date, your location</strong>.</td>
							<td><i>
								<Link to="/contact">
									Contact us
								</Link></i>
							</td>
						</tr>
						</tbody>
					</table>
				</div>
				<div className="col-md-4">
					<h3>Class information</h3>
					<p>Classes start with a <strong>minimum of 3</strong> students and have a
						&nbsp;<strong>maximum of 10</strong> students.</p>
					<p>Want to know more? See our FAQ in the <Link to="/information">
						information page.
					</Link>.</p>
					<Link className="btn" to="/contact">Sign up!</Link>
				</div>
			</div>

			{/* Row - Calendar legend*/}
			<div className="row">
				<div className="col text-center">
					<hr style={{width: '50%'}}/>
					<h2>Calendar</h2>
				</div>
			</div>
			<div className="row">
				<div className="col-md-6" lang="en">
					<h2>
						Booking a private class?&nbsp;
						<img src={flagUs} alt="Flag English text"/>
					</h2>
					<p>
						Want to book a class? Avoid disappointments and first check wether your date is available.
					</p>
					<p>Next, please contact <a href="mailto:info@reacttraining.nl">info@reacttraining.nl</a> or use the
						&nbsp;<Link to="/contact">Contact Form</Link> to discuss options.</p>
					<hr/>
					<h3>Legend:</h3>
					<ul>
						<li><strong>Bezet</strong> - Date is booked. Contact us for waiting list opportunities.</li>
						<li><strong>Optie</strong> - Date has been booked as an option. You can also take an option on
							these dates.
						</li>
					</ul>
					<p><em>Please note: No rights can be derived from calendar below.</em></p>
				</div>
				<div className="col-md-6" lang="nl">
					<h2>
						In-company training boeken?&nbsp;
						<img src={flagNl} alt="vlag Nederlandse tekst"/>
					</h2>
					<p>
						Een training boeken? Voorkom teleurstellingen en check eerst of uw gewenste datum beschikbaar
						is.
					</p>
					<p>
						Neem vervolgens contact op via <a
						href="mailto:info@reacttraining.nl">info@reacttraining.nl</a> of via het
						&nbsp;
						<Link to="/contact">Contactformulier</Link> om verdere mogelijkheden te bespreken.
					</p>
					<hr/>
					<h3>Legenda:</h3>
					<ul>
						<li><strong>Bezet</strong> - datum is definitief geboekt en kan niet worden afgenomen. Neem
							contact op voor wachtlijst-mogelijkheden.
						</li>
						<li><strong>Optie</strong> - datum is in optie genomen. U kunt ook een optie nemen op deze
							dagen.
						</li>
					</ul>
					<p><em>Let op: aan onderstaande agenda kunnen geen rechten worden ontleend.</em></p>
				</div>
			</div>

			{/*Row with Google Calendar*/}
			<div className="row">
				<div className="col text-center">
					<iframe
						title="calendar"
						src="https://calendar.google.com/calendar/embed?showCalendars=0&amp;height=600&amp;wkst=1&amp;bgcolor=%23cccccc&amp;src=ujd2afe5i9mscgr49dbhd8vnjc%40group.calendar.google.com&amp;color=%23A32929&amp;src=nl.dutch%23holiday%40group.v.calendar.google.com&amp;color=%230F4B38&amp;ctz=Europe%2FAmsterdam"
						style={{border: "solid 1px #777"}} width="800" height="600" frameBorder="0" scrolling="no"/>
				</div>
			</div>
		</div>
	);
}

export default Dates;
