import React, {useEffect} from 'react';
import {Link} from "react-router-dom";

function Information() {

	// scroll to top on enter
	useEffect(() => {
		window.scrollTo(0, 0)
	}, []);


	return (
		<div className="container">
			<h2 className="text-center my-3">Information, Equipment and FAQ</h2>
			<div className="row">
				{/* left column*/}
				<div className="col-md-6">
					<h3>Information</h3>
					<p>All public classes are held in our classroom in Zutphen, NL,
						near the centre of this historic town. </p>

					<h3>Equipment</h3>
					<p>Students should bring their own laptop (Mac, Linux or Windows) with
						&nbsp;<a
							rel="noopener noreferrer"
							target="_blank"
							href="https://www.google.com/chrome/browser/desktop/index.html">Chrome</a> installed as a
						browser.
						You can use your preferred editor. We recommend using <a
							rel="noopener noreferrer"
							href="http://www.jetbrains.com/webstorm/download/"
							target="_blank">WebStorm</a> or
						&nbsp;<a
							rel="noopener noreferrer"
							href="https://code.visualstudio.com/"
							target="_blank">Visual Studio Code.</a></p>
					<p>Wifi is available at our location, as well as coffee, tea, soft drinks, snacks and beverages.</p>

					<h3>FAQs</h3>
					<p>
						<strong>Q: What language are the public classes held in?</strong></p>
					<p>A: Public classes are typically held in Dutch, but English is definitely an option.
						Let your preferred language know when signing up.</p>
					<p>Private classes are held in the preferred language of the client. We discuss that prior to the
						start of
						the training.</p>
					<p>
						<strong>Q: Do I receive a certificate of completion?</strong></p>
					<p>A: Yes, if desired, you can obtain a Certificate of completion after the course. Just let us know
						once you're there, so we can issue the certificate via email afterwards.</p>

					<p><strong>Q: How should the course be payed?</strong></p>
					<p>A: After signing up, you or your company will receive an invoice for the amount due. You
						<strong>only</strong> receive an invoice if the class is guaranteed to start.
						After receiving, just pay the invoice within a due date of 30 days.</p>

					<p><strong>Q: Zutphen doesn't work for us. Can you teach at our location?</strong></p>
					<p>A: Yes, absolutely. Typically classes at your location are given at a fixed daily price,
						unrelated to the number of students. Contact us for information on availablity, dates and
						prices.</p>

					<p><strong>Q: What are the training hours?</strong></p>
					<p>A: Classes are from 9:00 - ca. 16:30hr.</p>
					<p>At request we can also organize afternoon/evening courses (13:00-20:30hr) or in weekends, if this
						better suits your needs. There is an additional fee for evening or weekend classes.</p>

					<p><strong>Q: Do I need to know about JavaScript?</strong></p>
					<p>Yes, definitely. React is built on top of JavaScript and working with it assumes thorough
						JavaScript knowledge. It is <strong>not</strong> and extension to HTML, as its counterparts
						Angular and
						Vue are.
					</p>
					<p>
						If you feel your JavaScript knowledge can use a little refresher, contact us for
						teaching opportunities. We can create a custom course that teaches both JavaScript and React.
					</p>
				</div>
				{/* right column */}
				<div className="col-md-6">
					<p><strong>Q: Can I receive a discount?</strong></p>
					<p>A: Sure! Just make sure to bring one or more colleagues with you. If two or more students can be
						billed
						on the same invoice, a discount can be applied. Contact us for details.</p>

					<p><strong>Q: When is the class guaranteed to start?</strong></p>
					<p>A: The class is guaranteed to start if 3 or more students are enrolled. See the
						<Link to="/dates">Dates</Link> page for more information. Otherwise you can rebook your
						training to another date at no cost.
					</p>

					<p><strong>Q: What about the instructors?</strong></p>
					<p>A: Your main instructor is <a
						rel="noreferrer noopener"
						href="https://www.linkedin.com/in/peterkassenaar/"
						target="_blank">
						Peter Kassenaar
					</a>. Peter has 20+ years of experience in (web)development and is
						the author of over 100 IT-books. He has taught thousands of students over the years and provided
						classes
						for companies based in The Netherlands, Belgium, United Kingdom, United States of America and
						Saudi
						Arabia. Learn more about Peter on his personal website at
						&nbsp;<a href="https://kassenaar.com/over/"
								 rel="noopener noreferrer"
								 target="_blank">kassenaar.com</a>. In large classes, we
						invite assistant instructors who ensure the hands-on workshop run smoothly. They may also teach
						some topics.
					</p>

					<p><strong>Q: Do the classes cover server-sided APIs and data services?</strong></p>
					<p>A: We use server-sided APIs extensively (in the examples we use pre-built, open APIs), but the
						class does not touch on development of such data services. This would require to
						include Java, C# or PHP in the class and the days are allready chock full of front-end code. As
						far as React is concerned, it doesn't matter on which platform the backend is built so we don't
						go
						into that topic to deeply.</p>

					<p><strong>Q: Do I need to know about NodeJS?</strong></p>
					<p>A: We discuss using NodeJS and NPM (and assist you installing it, if necessary), but the core
						concepts of NodeJS are outside the scope of this class. NodeJS is discussed and used as a
						development tool for React applications. However, it is absolutely not required in production
						environments. The pros and cons of this approach are discussed in the training.</p>

					<p><strong>Q: Do I need to know about HTML and CSS?</strong></p>
					<p>A: We assume you are at least a little bit familiar with HTML and CSS, but you don't have to be a
						wizard or guru in either technique. These are classes about code, not about design. For example,
						we use
						Bootstrap as a CSS framework (though we touch on other, React specific design frameworks) for
						simple design of our applications.</p>

					<p><strong>Q: Can I stay in Zutphen during the course?</strong></p>
					<p>A: Sure, why not? Zutphen is a small town with a historic background that goes back to the 11th
						century. It has lots of restaurants, museums, sightseeing opportunities and nice hotels or
						Bed &amp; Breakfasts. We recommend staying at the <a
							href="https://www.hampshirehotelsgravenhofzutphen.com/"
							rel="noreferrer noopener"
							target="_blank">Hampshire
							Hotel 's Gravenhof</a> which is only a 10 minute walk from the training location.</p>
				</div>
			</div>
		</div>
	);
}

export default Information;
